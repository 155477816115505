import { render, staticRenderFns } from "./dieVideo.vue?vue&type=template&id=1b7b39f0&scoped=true"
import script from "./dieVideo.vue?vue&type=script&lang=js"
export * from "./dieVideo.vue?vue&type=script&lang=js"
import style0 from "./dieVideo.vue?vue&type=style&index=0&id=1b7b39f0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b7b39f0",
  null
  
)

export default component.exports