<template>
  <div class="_footer">
    <div class="_footer_auto">
      <div class="_lgBox">
        <img src="../../assets/image/nav-img/logo1.png" alt="" />
        <!-- <img :src="$IMG_URL + $store.state.webData.web_logo" alt=""> -->
      </div>
      <div class="_textBox">
        <div class="_tip">
          <span @click="goOther">法律声明</span>
          <span class="_s">|</span>
          <span @click="goY">隐私条款</span>
          <span class="_s">|</span>
          <span @click="goMap">网站地图</span>
        </div>
        <p class="_adress">地址：{{ $store.state.webData.web_addr }}</p>
        <p class="_auth">
          <span id="_ideConacs"
            ><span id="_ideConac"
              ><a
                href="https://bszs.conac.cn/sitename?method=show&amp;id=0EA664027377724FE06310291AACA0EA"
                target="_blank"
                ><img
                  id="imgConac"
                  vspace="0"
                  hspace="0"
                  border="0"
                  src="https://dcs.conac.cn/image/blue.png"
                  data-bd-imgshare-binded="1" /></a></span
          ></span>

          <span class="_nnn">
            {{ $store.state.webData.web_copyright }} 

            <span @click="goBa" style="cursor: pointer">{{
              $store.state.webData.web_beian
            }}</span>
          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
//
export default {
  mounted() {
    // let script = document.createElement("script");
    // script.type = "text/javascript";
    // script.src =
    //   "https://dcs.conac.cn/js/17/253/0000/40921581/CA172530000409215810001.js";

    // const ele = document.querySelector("#_ideConacs");
    // ele.appendChild(script);
  },
  methods: {
    goOther() {
      let url = location.origin;
      window.open(`${url}/#/notice`);
    },
    goY() {
      let url = location.origin;
      window.open(`${url}/#/policy`);
    },
    goBa() {
      window.open(`http://beian.miit.gov.cn/`);
    },
    goMap() {
      let url = location.origin;

      window.open(`${url}/#/webMap`);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../common/mixin.scss";

._nnn {
  margin-top: 10px;
}
._footer {
  width: 100%;

  background: #342f2a;

  ._footer_auto {
    width: 66.6%;
    padding: 60px 0;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    ._lgBox {
      img {
      }
    }

    ._textBox {
      ._tip {
        font-size: 18px;
        color: white;

        ._s {
          margin: 0 10px;
        }

        span {
          cursor: pointer;
        }
      }

      ._adress {
        color: #969696;
        font-size: 18px;
        margin: 23px 0 17px;
      }

      ._auth {
        display: flex;
        color: #969696;
        font-size: 18px;
        // align-items: center;
      }
    }
  }
}


@include respondTo('phone'){

  ._footer{
    ._footer_auto{
      width: 100%;
      padding: 0 8px;
      display: block;

      ._textBox{
        ._tip{
          font-size: 16px;
        }
        ._adress{
          margin: 10px 0 10px;
          font-size: 16px;

        }
        ._auth{
          font-size: 16px;
          display: flex;
          align-items: center;
          ._nnn{
            margin-top: 0;
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
  #imgConac {
  width: 45px !important;
  margin-left: -8px;
}
</style>