<template>
  <div class="_wrap">
    <div class="_auto">
      <myBread name="互动交流" path='/exchange' child="合作交流" childPath='/exchangeInfo'></myBread>

      <div class="_cs">
        <div class="_top">
          <div class="_tabs">
            <ul>
              <li>合作交流</li>
            </ul>
          </div>
        </div>

        <div class="_list">
          <div
            class="_li"
            v-for="(item, index) in list"
            :key="index"
            @click="goDetail(item)"
          >
            <div class="_img" v-if="item.cover != ''">
              <img :src="$IMG_URL + item.cover" alt="" />
            </div>
            <div class="_time">
              <div class="_d">{{ item.pub_time.substring(8, 10) }}</div>
              <div class="_shu">|</div>
              <div class="_y">
                <p>{{ item.pub_time.substring(0, 4) }}</p>
                <p>{{ item.pub_time.substring(5, 7) }}</p>
              </div>
            </div>

            <div :class="{ _text: true, noImg: item.cover == '' }">
              <h4>{{ item.title }}</h4>
              <p>
                {{ item.descs }}
              </p>
            </div>
          </div>

          <div class="_page">
            <pagination
              v-show="total > 0"
              :total="total"
              :page.sync="queryParams.page"
              :limit.sync="queryParams.pagesize"
              @pagination="getList"
            />
          </div>
        </div>
      </div>
    </div>
    <otherBwg />
  </div>
</template>

<script>
import { getArticle, addHit } from "@/api/getData";
export default {
  data() {
    return {
        list:[],
      total: 0,
      queryParams: {
        cate_id: "43",
        page: 1,
        pagesize: 10,
      },
    };
  },
        // 动态设置页面标题、关键字和描述等属性  
        metaInfo() {

return {
  title: `合作交流_龙门石窟研究院_官网`,
  // meta: [
  //   { name: "keywords", content: "" },
  //   { name: "description", content: this.desc },
  // ]
}
},
  async mounted() {
    await this.getList();

    

  },
  methods: {
    async getList() {
      await getArticle(this.queryParams).then((res) => {
        this.list = res.data.data;
        this.total = res.data.total;
      });
    },
    goDetail(item) {
      let url = location.origin;
      const params = {
        id: item.id,
      };

      addHit(params);
      if (item.link != "" && item.link != null) {
        window.open(item.link);
      } else {
        window.open(`${url}/#/exchangeDetail?id=${item.id}`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../common/mixin.scss";

._cs {
  padding: 0 20px;
}

._top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #a88b61;

  ._tabs {
    ul {
      display: flex;

      li {
        font-size: 22px;
        color: #333;
        padding: 14px 30px;
        cursor: pointer;
        background-color: #a88b61;
        color: white;
      }
    }
  }
}

._list {
  ._li {
    display: flex;
    align-items: center;
    border-bottom: 1px dashed #e2e2e2;
    padding: 20px 0;
    cursor: pointer;

    ._img {
      width: 198px;
      height: 123px;
      overflow: hidden;
      border-radius: 5px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all 0.5s;
      }

      img:hover {
        transform: scale(1.1);
      }
    }

    ._time {
      display: flex;
      align-items: center;
      margin: 0 30px;

      ._d {
        color: #333;
        font-size: 28px;
      }

      ._shu {
        color: #d9d9d9;
        margin: 0 10px;
      }

      ._y {
        color: #666;
        font-size: 14px;
      }
    }

    ._text {
      width: 70%;

      h4 {
        color: #333;
        font-size: 20px;
        margin-bottom: 10px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      p {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        color: #888;
        line-height: 26px;
      }
    }

    .noImg {
      width: 100%;
    }
  }
}

._page {
  text-align: center;
}


@include respondTo("phone"){
  ._wrap{
    ._bread{
      margin: 12px 0;
    }
    ._cs{
      padding: 0;
    }
    ._list{
      ._li{
        ._img{
          width: 100px;
          height: 64px;
        }
        ._time{
          margin: 0 10px;
          ._d{
            font-size: 18px;
            font-weight: bold;
            
          }
        }
        ._text{
          width: 40%;
        }
      }
    }
  }
}
</style>