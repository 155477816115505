<template>
  <div class="_wrap">
    <div class="_auto">
      <!-- <myBread name="石窟寺研究" path="/sksInfo" child="征稿启事" childPath='/solictiDetail'></myBread> -->
      <myBread name="石窟专委会" path="/committee" child="石窟寺研究" childPath='/sksInfo' tChild='征稿启事' tChildPath='/solictiDetail'></myBread>


      <div class="_content">
        <h4>{{ detail.title }}</h4>
        <p>时间：{{ detail.pub_time }}</p>

        <div class="_html" ref="myHtml" v-html="detail.content"></div>
      </div>
    </div>
    <otherBwg></otherBwg>
  </div>
</template>

<script>
import { getArticle } from "@/api/getData";
export default {
  data() {
    return {
      detail: {},
    };
  },
        // 动态设置页面标题、关键字和描述等属性  
        metaInfo() {

return {
  title: `征稿启事_龙门石窟研究院_官网`,
  // meta: [
  //   { name: "keywords", content: "" },
  //   { name: "description", content: this.desc },
  // ]
}
},
  async mounted() {
    await this.getData()
        setTimeout(() => {
      this.$scallHtmlImg();
    }, 50);

    

  },
  methods: {
    async getData() {
      const params = {
        cate_id: "60",
      };
     await getArticle(params).then((res) => {
        this.detail = res.data.data[0]
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../common/mixin.scss";

._content {
  padding-bottom: 60px;
  h4 {
    font-size: 26px;
    margin-bottom: 20px;
    text-align: center;
  }

  > p {
    color: #999999;
    text-align: center;
  }
  ._html {
    padding: 0 60px;
    margin-top: 40px;
  }
}

::v-deep ._html p{
    line-height: 36px;
}


@include respondTo('phone'){
  ._wrap{
    ._bread{
      display: none;
    }
    ._content{
      ._html{
        padding: 0;
        margin-top: 20px;
      }
    }
  }
}
</style>