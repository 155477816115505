<template>
  <div class="_wrap">
    <div class="_auto">
      <myBread name="石窟专委会" path="/committee"></myBread>

      <div class="_top" style="margin: 0 20px">
        <div class="_tabs1">
          <ul>
            <li>石窟专业委员会</li>
          </ul>
        </div>
      </div>

      <div class="_first">
        <div class="_l">
          <img src="../../assets/image/commit-img/image 779.png" alt="" />
        </div>
        <div class="_r">
          <div class="_html">
            <div class="_nr" v-html="skzywyh.content"></div>

            <div class="_div">
              <div class="_text">
                <p>微信扫一扫</p>
                <p>关注我们，获取更多</p>
              </div>
              <img src="../../assets/image/commit-img/code.jpg" alt="" />
            </div>
          </div>
        </div>
      </div>

      <div class="_noList" id="jgsz">
        <div class="_lf">
          <div class="_top">
            <div class="_tabs1">
              <ul>
                <li>机构设置</li>
              </ul>
            </div>
          </div>

          <div class="_nr">
            <div class="_l">
              <div
                :class="{ _li: true }"
                v-for="(item, index) in jgList"
                :key="index"
                @click="changeTab(item, index)"
              >
                <p :class="{ _active: current == index }"></p>
                <span :class="{ _active1: current == index }">{{
                  item.c_name
                }}</span>
              </div>
            </div>

            <div class="_r">
              <div class="_ul">
                <div
                  class="_rLi"
                  v-for="(item, index) in personList"
                  :key="index"
                >
                  <h4>
                    <img src="../../assets/image/commit-img/dot.png" alt="" />
                    <span>{{ item.title }}</span>
                  </h4>
                  <p>{{ item.sub_title }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="_rt">
          <div class="_top">
            <h4>《石窟寺研究》</h4>
            <div class="_more" @click="goYj">MORE</div>
          </div>

          <div class="_rtList">
            <div class="swiper mySwiper">
              <div class="swiper-wrapper">
                <div
                  class="swiper-slide"
                  v-for="(item, index) in sksyjList"
                  :key="index"
                  @click="lookPdf(item)"
                >
                  <div class="_img">
                    <img :src="$IMG_URL + item.cover" alt="" />
                  </div>
                  <div class="_bb">
                    {{ item.title }}
                  </div>
                </div>
              </div>
              <div class="swiper-scrollbar"></div>
            </div>
          </div>
        </div>
      </div>

      <div class="_hd">
        <div class="_top">
          <div class="_tabs1">
            <ul>
              <li
                :class="{ _active2: current2 == index }"
                v-for="(item, index) in tabsList2"
                :key="index"
                @click="changeTab2(item, index)"
              >
                {{ item.name }}
              </li>
            </ul>
          </div>
          <div class="_more" @click="goLj">MORE</div>
        </div>

        <div class="_list">
          <div class="swiper mySwiper1">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="(item, index) in activeList"
                :key="index"
                @click="goDetail(item)"
                style="cursor: pointer"
              >
                <div class="_img">
                  <img :src="$IMG_URL + item.cover" alt="" />
                </div>
                <div class="_text">
                  <h4>{{ item.title }}</h4>
                  <p>{{ item.pub_time }}</p>
                </div>
              </div>
            </div>
            <div class="swiper-pagination"></div>
          </div>
        </div>
      </div>
    </div>

    <otherBwg></otherBwg>
  </div>
</template>

<script>
import { getArticle, institution, getDetail, addHit } from "@/api/getData";
import Swiper from "swiper";

export default {
  data() {
    return {
      current: 0,
      current2: 0,
      tabsList2: [
        {
          name: "专题活动",
        },
        {
          name: "历届年会",
        },
      ],
      skzywyh: {},
      jgList: [],
      personList: [],
      sksyjList: [],
      activeList: [],
      activeQuery: {
        page: 1,
        pagesize: 10,
        cate_id: "49",
      },

      swiper1: null,
    };
  },
  // 动态设置页面标题、关键字和描述等属性
  metaInfo() {
    return {
      title: `石窟专委会_龙门石窟研究院_官网`,
      // meta: [
      //   { name: "keywords", content: "" },
      //   { name: "description", content: this.desc },
      // ]
    };
  },
  async mounted() {
    await this.getData();
    await this.getActive();
    this.initSwiper();
    this.initSwiper1();

    let tit = this.$route.query.tit;
    if (tit == "机构设置") {
      this.$scrollToSection("jgsz");
    } else {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // 平滑滚动
      });
    }
  },
  methods: {
    changeTab(item, index) {
      this.current = index;
      this.personList = item.person;
    },
    async changeTab2(item, index) {
      this.current2 = index;
      if (index == 0) {
        this.activeQuery.cate_id = "49";
      } else if (index == 1) {
        this.activeQuery.cate_id = "50";
      }
      await this.getActive();

      this.swiper1.destroy();

      this.initSwiper1();
    },
    goYj() {
      let url = location.origin;
      window.open(`${url}/#/sksInfo`);
    },
    lookPdf(item) {
      const params = {
        id: item.id,
      };
      addHit(params);
      getDetail(params).then((res) => {
        window.open(this.$IMG_URL + res.data.pdf);
      });
    },
    goLj() {
      let tit;
      if (this.current2 == 0) {
        tit = "专题活动";
      } else {
        tit = "历届年会";
      }
      let url = location.origin;
      window.open(`${url}/#/meetInfo?tit=${tit}`);
    },
    goDetail(item) {
      let tit;
      if (this.current2 == 0) {
        tit = "专题活动";
      } else {
        tit = "历届年会";
      }
      let url = location.origin;

      if (item.link != "" && item.link != null) {
        window.open(item.link);
      } else {
        window.open(`${url}/#/meetDetail?id=${item.id}&tit=${tit}`);
      }
    },
    initSwiper() {
      var swiper = new Swiper(".mySwiper", {
        spaceBetween: 20,
        slidesPerView: 2,
        freeMode: true,
        watchSlidesProgress: true,
        scrollbar: {
          el: ".swiper-scrollbar",
          hide: false,
        },
      });
    },
    initSwiper1() {
      var slidesPerView;
      if (document.body.clientWidth > 800) {
        slidesPerView = 3;
      } else {
        slidesPerView = 1;
      }
      this.swiper1 = new Swiper(".mySwiper1", {
        slidesPerView: slidesPerView,
        spaceBetween: 20,

        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      });
    },

    async getData() {
      const params = {
        cate_id: "46",
      };
      await getArticle(params).then((res) => {
        this.skzywyh = res.data.data[0];
      });

      // 机构设置
      await institution().then((res) => {
        this.jgList = res.data;
        this.personList = res.data[0].person;
      });

      // 石窟寺研究

      const params1 = {
        cate_id: "48",
      };
      await getArticle(params1).then((res) => {
        this.sksyjList = res.data.data;
      });
    },

    async getActive() {
      await getArticle(this.activeQuery).then((res) => {
        this.activeList = res.data.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../common/mixin.scss";

._more {
  border: 1px solid #bb945b;
  color: #303030;
  font-size: 14px;
  padding: 8px 14px;
  cursor: pointer;
}

._top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f9f9f9;
  border-top: 1px solid #a88b61;
  ._tabs1 {
    ul {
      display: flex;

      li {
        color: #333;
        font-size: 22px;
        padding: 14px 30px;
        cursor: pointer;
        background-color: #a88b61;
        color: white;
      }
    }
  }

  ._more {
    margin-right: 20px;
  }
}

._first {
  margin: 0 20px;
  margin-top: 20px;
  display: flex;

  ._l {
    width: 238px;
    height: 378px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all 0.5s;
    }

    img:hover {
      transform: scale(1.1);
    }
  }

  ._r {
    flex: 1;
    margin-left: 30px;
    padding-right: 20px;
    height: 378px;
    ._html {
      color: #333;
      position: relative;
      height: 100%;
      > p {
        line-height: 25px;
      }

      ._di {
        margin: 30px 0;
      }

      ._nr {
        height: 100%;
      }

      ._div {
        position: absolute;
        bottom: 0;
        right: 20px;
        display: flex;

        ._text {
          margin-top: 50px;
          margin-right: 9px;
        }

        img {
          width: 108px;
          height: 108px;
        }
      }
    }
  }
}

::v-deep ._nr p {
  line-height: 34px !important;
}

._noList {
  margin: 0 20px;
  margin-top: 40px;
  display: flex;

  ._lf {
    width: 59.7%;

    ._top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #f9f9f9;
      border-top: 1px solid #a88b61;

      ._tabs1 {
        ul {
          display: flex;

          li {
            color: #333;
            font-size: 22px;
            padding: 15px 30px;
            cursor: pointer;
            color: white;
            background-color: #a88b61;
          }
        }
      }

      ._more {
        margin-right: 20px;
      }
    }

    ._nr {
      margin-top: 10px;
      display: flex;

      ._l {
        width: 210px;
        height: 390px;
        background: #f8f5ea;

        ._li {
          text-align: center;
          font-size: 18px;
          color: #a88b61;
          height: 52px;
          line-height: 52px;
          cursor: pointer;

          span {
            z-index: 9;
            position: relative;
          }
        }

        ._active {
          background-image: url("../../assets/image/commit-img/bg.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          width: 230px;
          height: 52px;
          position: absolute;
        }

        ._active1 {
          color: white;
        }
      }

      ._r {
        flex: 1;
        height: 390px;
        background: #f9f9f9;

        ._ul {
          overflow-y: scroll;
          height: 390px;

          padding: 0 54px 0 54px;
        }

        ._ul::-webkit-scrollbar {
          width: 8px;
          height: 10px;
          background-color: transparent;
        }

        ._ul::-webkit-scrollbar-thumb {
          border-radius: 20px;
          background: #d9d9d9;
        }

        ._rLi {
          border-bottom: 1px dashed #dbdbdb;
          padding-bottom: 24px;
          padding-top: 24px;

          h4 {
            span {
              font-weight: bold;
              margin-left: 10px;
            }
          }

          p {
            margin-left: 20px;
          }
        }
      }
    }
  }

  ._rt {
    width: 38%;
    margin-left: 28px;

    ._top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #f4f4f4;
      border-top: none;
      background: transparent;

      h4 {
        border-radius: 4px 4px 0 0;
        background: #f8f5ea;
        color: #bb945b;
        border-bottom: 1px solid #a88b61;
        padding: 16px 24px;
        font-size: 20px;
      }
    }

    ._rtList {
      margin-top: 10px;
    }
  }
}

._hd {
  margin: 0 20px;
  margin-top: 40px;
  padding-bottom: 20px;

  ._top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f9f9f9;
    border-top: 1px solid #a88b61;

    ._tabs1 {
      ul {
        display: flex;

        li {
          color: #333;
          font-size: 22px;
          padding: 14px 30px;
          cursor: pointer;
          background: transparent;
        }

        ._active2 {
          background-color: #a88b61;
          color: white;
        }
      }
    }

    ._more {
      margin-right: 20px;
    }
  }

  ._list {
    margin-top: 20px;

    ._img {
      width: 400px;
      height: 240px;
      overflow: hidden;
      border-radius: 5px;
      cursor: pointer;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all 0.5s;
      }

      img:hover {
        transform: scale(1.1);
      }
    }

    ._text {
      background: #f9f9f9;
      padding: 10px 16px;
      width: 400px;

      h4 {
        font-size: 18px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      p {
        color: #999;
        font-size: 14px;
      }
    }
  }

  .mySwiper1 {
    position: relative;
    height: 380px;
    overflow: hidden;
  }
}

::v-deep .swiper-container-horizontal > .swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 30px;
}

::v-deep .swiper-pagination-bullet {
  width: 12px;
  height: 6px;
  background: #e2e2e2;
  border-radius: 3px;
  opacity: 1;
}

::v-deep .swiper-pagination-bullet-active {
  width: 20px;
  height: 6px;
  background: #a88b61;
  border-radius: 3px;
}

.mySwiper {
  position: relative;
  overflow: hidden;
  height: 388px;
  .swiper-slide {
    ._img {
      width: 100%;
      height: 297px;
      overflow: hidden;
      border-radius: 5px;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    ._bb {
      width: 100%;
      padding: 12px 0;
      text-align: center;
      background: #f6f6f6;
      color: #666666;
      font-size: 18px;
      margin-top: 14px;
      border: 1px solid #dfdfdf;
    }
  }
}

.swiper-container-horizontal > .swiper-scrollbar {
  bottom: 0;
}

::v-deep .swiper-scrollbar-drag {
  background: #a88b61;
}

@include respondTo("phone") {
  ._wrap {
    ._bread {
      margin: 12px 0;
    }
    ._top {
      margin: auto !important;
    }
    ._first {
      margin: auto;
      margin-top: 20px;
      display: block;
      ._l {
        width: 100%;
      }
      ._r {
        margin: auto;
        padding-right: 0;
        height: auto;
        ._html {
          ._div {
            position: static;
            ._text {
              // display: none;
            }
          }
        }
      }
    }

    ._noList {
      margin: auto;
      margin-top: 20px;
      display: block;
      ._lf {
        width: 100%;
        ._nr {
          ._l {
            width: 120px;
            ._active {
              width: 132px;
            }
          }

          ._r {
            ._ul {
              padding: 20px;
              ._rLi {
                p {
                  line-height: 26px !important;
                }
              }
            }
          }
        }
      }
      ._rt {
        width: 100%;
        margin-left: 0;
        margin-top: 20px;
      }
    }

    ._hd {
      margin: auto;
      margin-top: 20px;
      ._top {
        ._tabs1 {
          ul {
            li {
              font-size: 18px;
              padding: 16px 20px;
            }
          }
        }
      }

      ._list {
        ._img {
          width: 100%;
        }
      }
    }
  }
}
</style>