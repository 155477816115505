



export function scallHtmlImg() {

    const boxWidth = this.$refs.myHtml.offsetWidth
    // console.log(boxWidth)


    //通过querySelectorAll选中所有的img标签
    var images = document.querySelectorAll('._html img');
    // console.log(images); // 输出结果为包含所有图片的NodeList对象

    images.forEach((item, index) => {
        item.onload = function () {
            let imgWidth = item.width
            
            if (imgWidth < boxWidth) {
                // console.log("xiaoyu")
                item.style.width = imgWidth + 'px'
            } else {
                // console.log("大于")
                item.style.width = boxWidth + 'px'
            }
        }





    })











}