<template>
  <div class="_wrap">
    <div class="_auto">
      <myBread name="安全保卫" path="/security"></myBread>

      <div class="_sf" id="sfcs">
        <div class="_l_text" v-if="sfList.length > 0">
          <h4>三防措施—— {{ sfList[0].title }}</h4>
          <p class="_p" v-html="sfList[0].content"></p>

          <div class="_tabs">
            <ul>
              <li
                :class="{ _active: current == index }"
                v-for="(item, index) in tabsList"
                :key="index"
                @click="changeTab(item, index)"
              >
                {{ item.name }}
              </li>
            </ul>
          </div>
        </div>

        <div class="_r_img" v-if="sfList.length > 0">
          <img :src="$IMG_URL + sfList[0].cover" alt="" />
        </div>
      </div>

      <div class="_qt" id="znjj">
        <div class="_top">
          <div class="_tabs1">
            <ul>
              <li
                :class="{ _active: current1 == index }"
                v-for="(item, index) in tabsList1"
                :key="index"
                @click="changeTab1(item, index)"
              >
                {{ item.name }}
              </li>
            </ul>
          </div>

          <div class="_list">
            <div
              class="_li"
              v-for="(item, index) in aqgzList"
              :key="index"
              @click="goDetail(item)"
            >
              <div class="_img">
                <img :src="$IMG_URL + item.cover" alt="" />
              </div>
              <div class="_bb">
                <h4>{{ item.title }}</h4>
                <p>{{ item.pub_time }}</p>
              </div>
            </div>
          </div>
          <div class="_page">
            <pagination
              v-show="total > 0"
              :total="total"
              :page.sync="qagzQuery.page"
              :limit.sync="qagzQuery.pagesize"
              @pagination="getList"
            />
          </div>
        </div>
      </div>
    </div>

    <otherBwg></otherBwg>
  </div>
</template>

<script>
import { getArticle, addHit } from "@/api/getData";
export default {
  data() {
    return {
      current: 0,
      tabsList: [
        {
          name: "人防",
        },
        {
          name: "技防",
        },
        {
          name: "物防",
        },
      ],
      current1: 0,
      tabsList1: [
        {
          name: "安全工作",
        },
        {
          name: "学习培训、演练",
        },
        {
          name: "安防",
        },
        {
          name: "旅游高峰期",
        },
        {
          name: "助人为乐",
        },
      ],

      total: 0,

      sfQuery: {
        cate_id: "21", //人防
      },
      qagzQuery: {
        cate_id: "24", //安全工作
        page: 1,
        pagesize: 6,
      },

      sfList: [],
      aqgzList: [],
    };
  },
  // 动态设置页面标题、关键字和描述等属性
  metaInfo() {
    return {
      title: `安全保卫_龙门石窟研究院_官网`,
      // meta: [
      //   { name: "keywords", content: "" },
      //   { name: "description", content: this.desc },
      // ]
    };
  },
  async mounted() {
    await this.getData();
    await this.getList();
    let tit = this.$route.query.tit;
    console.log(tit);
    if (tit == "安全工作") {
      this.qagzQuery.cate_id = "24";
      this.current1 = 0;
    } else if (tit == "学习培训、演练") {
      console.log("denyyy");
      this.qagzQuery.cate_id = "25";
      this.current1 = 1;
    } else if (tit == "安防") {
      this.qagzQuery.cate_id = "26";
      this.current1 = 2;
    } else if (tit == "旅游高峰期") {
      this.qagzQuery.cate_id = "27";
      this.current1 = 3;
    } else if (tit == "助人为乐") {
      this.qagzQuery.cate_id = "28";
      this.current1 = 4;
    }

    await this.getList();

    const obj = {
      三防措施: "sfcs",
      安全工作: "znjj",
      安防: "znjj",
      "学习培训、演练": "znjj",
      旅游高峰期: "znjj",
      助人为乐: "znjj",
    };
    for (let i in obj) {
      if (tit == i) {
        this.$scrollToSection(obj[i]);
        return;
      } else {
        window.scrollTo({
          top: 0,
          behavior: "smooth", // 平滑滚动
        });
      }
    }
  },
  methods: {
    changeTab(item, index) {
      this.current = index;
      if (index == 0) {
        this.sfQuery.cate_id = "21";
      } else if (index == 1) {
        this.sfQuery.cate_id = "22";
      } else {
        this.sfQuery.cate_id = "23";
      }
      this.getData();
    },
    changeTab1(item, index) {
      this.current1 = index;
      if (index == 0) {
        this.qagzQuery.cate_id = "24";
      } else if (index == 1) {
        this.qagzQuery.cate_id = "25";
      } else if (index == 2) {
        this.qagzQuery.cate_id = "26";
      } else if (index == 3) {
        this.qagzQuery.cate_id = "27";
      } else if (index == 4) {
        this.qagzQuery.cate_id = "28";
      }
      this.qagzQuery.page = 1;
      this.getList();
    },
    async getList() {
      await getArticle(this.qagzQuery).then((res) => {
        this.aqgzList = res.data.data;
        this.total = res.data.total;
      });
    },
    goDetail(item) {
      let url = location.origin;

      const params = {
        id: item.id,
      };

      addHit(params);
      if (item.link != "" && item.link != null) {
        window.open(item.link);
      } else {
        window.open(`${url}/#/securityDetail?id=${item.id}`);
      }
    },

    async getData() {
      await getArticle(this.sfQuery).then((res) => {
        this.sfList = res.data.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../common/mixin.scss";
._page {
  text-align: center;
}

._sf {
  display: flex;
  padding: 0 20px;

  ._l_text {
    width: 58.8%;
    background-image: url("../../assets/image/security-img/bg.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 380px;
    padding: 30px 40px;

    h4 {
      color: #bb945b;
      font-size: 22px;
    }

    ._p {
      color: #666;
      margin-top: 20px;
      height: 200px;
    }

    ._tabs {
      margin-top: 30px;

      ul {
        display: flex;

        li {
          border: 1px solid #a88b61;
          color: #333;
          padding: 9px 50px;
          cursor: pointer;
          margin-right: 18px;
        }

        ._active {
          background-color: #a88b61;
          color: white;
        }
      }
    }
  }

  ._r_img {
    flex: 1;
    overflow: hidden;
    height: 380px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all 0.5s;
    }

    img:hover {
      transform: scale(1.1);
    }
  }
}

::v-deep ._p p {
  line-height: 26px;
}

._qt {
  margin-top: 40px;

  ._top {
    border-top: 1px solid #a88b61;
    margin: 0 20px;

    ._tabs1 {
      background: #f9f9f9;

      ul {
        display: flex;

        li {
          color: #333;
          font-size: 22px;
          padding: 14px 30px;
          cursor: pointer;
        }

        ._active {
          background-color: #a88b61;
          color: white;
        }
      }
    }

    ._more {
      margin-right: 20px;
    }
  }

  ._list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;

    ._li {
      margin-right: 20px;
      width: 32.2%;
      margin-bottom: 20px;
      cursor: pointer;

      ._img {
        overflow: hidden;
        width: 100%;
        height: 240px;
        border-radius: 5px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: all 0.5s;
        }

        img:hover {
          transform: scale(1.1);
        }
      }

      ._bb {
        background-color: #f9f9f9;
        padding: 10px 16px;
      }

      h4 {
        font-size: 18px;
        color: #333;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      p {
        font-size: 14px;
        color: #999;
      }
    }

    ._li:nth-child(3n) {
      margin-right: 0;
    }
  }
}

@include respondTo("phone") {
  ._wrap {
    margin-top: 100px;
    ._auto {
      width: 100%;
      padding: 0 8px;
      ._bread {
        margin: 12px 0;
      }
    }

    ._sf {
      padding: 0;
      display: block;
      ._l_text {
        width: 100%;
        padding: 30px 25px;
        ._tabs {
          ul {
            li {
              padding: 9px 22px;
            }
          }
        }
      }
      ._r_img {
        width: 100%;
      }
    }

    ._qt {
      margin-top: 20px;
      ._top {
        margin: auto;
        ._tabs1 {
          ul {
            li {
              font-size: 18px;
              padding: 8px 20px;
              text-align: center;
            }
          }
        }
      }

      ._list {
        margin-top: 20px;
        justify-content: space-between;
        ._li {
          width: 49%;
          margin-right: 0;
        }
      }
    }
  }
}
</style>