<template>
  <div class="_wrap">
    <div class="_auto">
      <myBread name="文旅融合" path='/cultural' child='走进龙门' childPath='/enterLmInfo'></myBread>

      <div class="_top">
        <div class="_tabs1">
          <ul>
            <li>走进龙门</li>
          </ul>
        </div>
        <!-- <div class="_more" @click="golm">MORE</div> -->
      </div>

      <div class="_tabs">
        <div
          :class="{ _li: true, _active: current == index }"
          v-for="(item, index) in tabsList"
          :key="index"
          @click="changeTab(item, index)"
        >
          {{ item.name }}
        </div>
      </div>

      <div class="_lmList">
        <div
          class="_li"
          v-for="(item, index) in list"
          :key="index"
          @click="goDetail(item)"
        >
          <img :src="$IMG_URL + item.cover" alt="" />
          <p>
            {{ item.title }}
          </p>
        </div>
      </div>

      <div class="_page">
        <pagination
          v-show="total > 0"
          :total="total"
          :page.sync="queryParams.page"
          :limit.sync="queryParams.pagesize"
          @pagination="getList"
        />
      </div>
    </div>

    <otherBwg></otherBwg>
  </div>
</template>

<script>
import { getArticle, addHit } from "@/api/getData";
export default {
  data() {
    return {
      list: [],
      total: 0,
      queryParams: {
        cate_id: "17",
        page: 1,
        pagesize: 12,
        label: "",
      },
      current: 0,
      tabsList: [
        {
          name: "全部",
        },
        {
          name: "西山石窟",
        },
        {
          name: "东山石窟",
        },
        {
          name: "白园",
        },
        {
          name: "香山寺",
        },
      ],
    };
  },
        // 动态设置页面标题、关键字和描述等属性  
        metaInfo() {

return {
  title: `走进龙门_龙门石窟研究院_官网`,
  // meta: [
  //   { name: "keywords", content: "" },
  //   { name: "description", content: this.desc },
  // ]
}
},
  async mounted() {
    await this.getList();

  },
  methods: {
   async getList() {
      await getArticle(this.queryParams).then((res) => {
        this.list = res.data.data;
        this.total = res.data.total;
      });
    },
    changeTab(item, index) {
      this.current = index;

      if (index == 0) {
        this.queryParams.label = "";
      } else if (index == 1) {
        this.queryParams.label = "西山石窟";
      } else if (index == 2) {
        this.queryParams.label = "东山石窟";
      } else if (index == 3) {
        this.queryParams.label = "白园";
      } else if (index == 4) {
        this.queryParams.label = "香山寺";
      }
      this.queryParams.page = 1;
      this.getList();
    },
    goDetail(item) {
      const params = {
        id: item.id,
      };
      addHit(params).then(() => {
        let url = location.origin;
        if (item.link != "" && item.link != null) {
          window.open(item.link);
        } else {
          window.open(`${url}/#/enterLmDetail?id=${item.id}`);

        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../common/mixin.scss";

._page {
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
._more {
  border: 1px solid #bb945b;
  color: #303030;
  font-size: 14px;
  padding: 8px 14px;
  cursor: pointer;
}

._top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f9f9f9;
  border-top: 1px solid #a88b61;
  margin: 0 20px;

  ._tabs1 {
    ul {
      display: flex;

      li {
        color: #333;
        font-size: 22px;
        padding: 14px 30px;
        cursor: pointer;
        background-color: #a88b61;
        color: white;
      }
    }
  }

  ._more {
    margin-right: 20px;
  }
}

._tabs {
  display: flex;
  margin: 0 20px;
  margin-top: 20px;

  ._li {
    width: 90px;
    height: 40px;
    border-radius: 6px;
    background: #f6f5f0;
    color: #666666;
    font-size: 15px;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
    margin-right: 20px;
  }

  ._active {
    background: #a88b61;
    color: white;
  }
}

._lmList {
  margin: 0 20px;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;

  ._li {
    width: 299px;
    height: 240px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    margin-bottom: 20px;
    margin-right: 12px;
    border-radius: 5px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all 0.5s;
    }

    img:hover {
      transform: scale(1.1);
    }

    p {
      position: absolute;
      bottom: 20px;
      width: 100%;
      text-align: center;
      color: white;
      font-weight: bold;
      font-size: 18px;
    }
  }
  ._li:nth-child(4n) {
    margin-right: 0;
  }
}

@include respondTo('phone'){
  ._wrap{
    ._bread{
      margin: 12px 0;
    }
    ._top{
      margin: auto;
    }
    ._tabs{
      margin: auto;
      margin-top: 20px;

      ._li{
        font-size: 14px;
        margin-right: 10px;
      }
      ._li:last-child{
        margin-right: 0;
      }
    }

    ._lmList{
      margin: auto;
      margin-top: 20px;
      ._li{
        
        width: 100%;
        margin-right: 0;
      }
    }
  }
}
</style>