<template>
  <div class="_wrap">
    <div class="_auto">
      <myBread name="互动交流" path="/exchange"></myBread>

      <div class="_first">
        <div
          class="_l"
          v-if="hdjlList.length > 0"
          @click="gojlDetail(hdjlList[0])"
        >
          <div class="_img">
            <img :src="$IMG_URL + hdjlList[0].cover" alt="" />
          </div>
          <p>{{ hdjlList[0].title }}</p>
        </div>
        <div class="_r">
          <div class="_top">
            <h4>合作交流</h4>
            <div class="_more" @click="gojl">MORE</div>
          </div>

          <div class="_jList">
            <div
              class="_li"
              v-for="(item, index) in hdjlList"
              :key="index"
              @click="gojlDetail(item)"
            >
              <h4>
                <p class="_dot"></p>
                <p class="_nr">{{ item.title }}</p>
              </h4>
              <p>{{ item.pub_time }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="_two">
        <div class="_top">
          <div class="_tabs1">
            <ul>
              <li>陈列外展</li>
            </ul>
          </div>
          <div class="_more" @click="goCg">MORE</div>
        </div>

        <div class="_clList">
          <div class="swiper mySwiper">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="(item, index) in clwzList"
                :key="index"
                @click="goDetail(item)"
                style="cursor: pointer"
              >
                <div class="_img">
                  <img :src="$IMG_URL + item.cover" alt="" />
                </div>
              </div>
            </div>
            <div class="swiper-scrollbar"></div>
          </div>
        </div>
      </div>

      <div class="_two _three">
        <div class="_top">
          <div class="_tabs1">
            <ul>
              <li>院藏展品</li>
            </ul>
          </div>
          <!-- <div class="_more" @click="goYc">MORE</div> -->
        </div>

        <div class="_otherImg" @click="goOther">
          <img src="../../assets/image/exchange-img/yczp.png" alt="" />
        </div>

        <!-- <div class="_ycList">
          <div
            class="_li"
            v-for="(item, index) in yczpList"
            :key="index"
            @click="goCpDetail(item)"
          >
            <div class="_img">
              <img :src="$IMG_URL + item.file" alt="" />
              <p>{{ item.title }}</p>
            </div>
          </div>
        </div> -->
      </div>
    </div>

    <otherBwg></otherBwg>
  </div>
</template>

<script>
import { getArticle, getRelic, reHit, addHit } from "@/api/getData";
import Swiper from "swiper";

export default {
  data() {
    return {
      hdjlList: [],
      clwzList: [],
      yczpList: [],
    };
  },
  // 动态设置页面标题、关键字和描述等属性
  metaInfo() {
    return {
      title: `互动交流_龙门石窟研究院_官网`,
      // meta: [
      //   { name: "keywords", content: "" },
      //   { name: "description", content: this.desc },
      // ]
    };
  },
  async mounted() {
    await this.getData();
    this.initSwiper();

    window.scrollTo({
      top: 0,
      behavior: "smooth", // 平滑滚动
    });
  },
  methods: {
    initSwiper() {
      var slidesPerView;
      if (document.body.clientWidth > 800) {
        slidesPerView = 4;
      } else {
        slidesPerView = 2;
      }
      var swiper = new Swiper(".mySwiper", {
        spaceBetween: 15,
        slidesPerView: slidesPerView,
        freeMode: true,
        watchSlidesProgress: true,
        scrollbar: {
          el: ".swiper-scrollbar",
          hide: false,
        },
      });
    },

    goOther() {
      window.open(`https://www.lmsk.org.cn/home/collection.html`);
    },
    gojl() {
      let url = location.origin;
      window.open(`${url}/#/exchangeInfo`);
    },
    goCg() {
      let url = location.origin;
      window.open(`${url}/#/exhibitInfo`);
    },
    goYc() {
      let url = location.origin;
      window.open(`${url}/#/concealInfo`);
    },

    gojlDetail(item) {
      const params = {
        id: item.id,
      };
      addHit(params).then(() => {
        let url = location.origin;
        if (item.link != "" && item.link != null) {
          window.open(item.link);
        } else {
          window.open(`${url}/#/exchangeDetail?id=${item.id}`);
        }
      });
    },
    goDetail(item) {
      const params = {
        id: item.id,
      };
      addHit(params).then(() => {
        let url = location.origin;

        // if (item.link != "" && item.link != null) {
        //   window.open(item.link);
        // } else{
        //     window.open(`${url}/#/exhibitDetail?id=${item.id}`);

        // }
        window.open(`${url}/#/exhibitDetail?id=${item.id}`);
      });
    },

    goCpDetail(item) {
      const params = {
        id: item.id,
      };
      let url = location.origin;

      reHit(params).then((res) => {
        if (item.link != "" && item.link != null) {
          window.open(item.link);
        } else {
          window.open(`${url}/#/concealDetail?id=${item.id}`);
        }
      });
    },

    async getData() {
      const params = {
        cate_id: "43", //互动交流
        page: 1,
        pagesize: 8,
      };
      await getArticle(params).then((res) => {
        this.hdjlList = res.data.data;
      });

      const params1 = {
        cate_id: "44", //陈列外展
        page: 1,
        pagesize: 30,
      };
      await getArticle(params1).then((res) => {
        this.clwzList = res.data.data;
      });

      const params2 = {
        page: 1,
        pagesize: 6,
      };
      await getRelic(params2).then((res) => {
        this.yczpList = res.data.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../common/mixin.scss";

._otherImg {
  width: 100%;
  height: 276px;
  margin-top: 20px;
  cursor: pointer;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.5s;
  }
  img:hover {
    transform: scale(1.1);
  }
}
._more {
  border: 1px solid #bb945b;
  color: #303030;
  font-size: 14px;
  padding: 8px 14px;
  cursor: pointer;
}

._first {
  margin: 0 20px;

  display: flex;

  ._l {
    width: 741px;
    cursor: pointer;

    ._img {
      width: 100%;
      height: 383px;
      overflow: hidden;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all 0.5s;
    }

    img:hover {
      transform: scale(1.1);
    }

    p {
      width: 100%;
      background-color: #f9f9f9;
      color: #303030;
      font-size: 22px;
      padding: 12px;
    }
  }

  ._r {
    width: 460px;
    margin-left: 30px;

    ._top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #f4f4f4;

      h4 {
        border-radius: 4px 4px 0 0;
        background: #f8f5ea;
        color: #bb945b;
        border-bottom: 1px solid #a88b61;
        padding: 17px 24px;
        font-size: 20px;
      }
    }

    ._jList {
      margin-top: 10px;

      ._li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 22px 0;
        cursor: pointer;

        h4 {
          display: flex;
          align-items: center;
          width: 75%;

          ._dot {
            display: inline-block;
            width: 4px;
            height: 4px;
            background: #bb945b;
            border-radius: 50%;
            margin-right: 10px;
          }

          ._nr {
            color: #424242;
            font-size: 18px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }

        p {
          color: #909090;
          font-size: 16px;
        }
      }
    }
  }
}

._two {
  margin: 0 20px;
  margin-top: 25px;
  overflow: hidden;

  ._top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f9f9f9;
    border-top: 1px solid #a88b61;

    ._tabs1 {
      ul {
        display: flex;

        li {
          color: #333;
          font-size: 22px;
          padding: 14px 30px;
          cursor: pointer;
          background-color: #a88b61;
          color: white;
        }
      }
    }

    ._more {
      margin-right: 20px;
    }
  }

  ._clList {
    margin-top: 20px;
    height: 230px;

    ._img {
      height: 182px;
    }
  }
}

._three {
  margin-top: 40px;

  ._ycList {
    display: flex;
    flex-wrap: wrap;
    margin-top: 23px;

    ._li {
      margin-right: 15px;
      margin-bottom: 20px;
      cursor: pointer;
      ._img {
        width: 400px;
        height: 300px;
        overflow: hidden;
        position: relative;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: all 0.5s;
        }

        img:hover {
          transform: scale(1.1);
        }
      }

      p {
        font-size: 22px;
        color: #333;
        text-align: center;
        position: absolute;
        bottom: 20px;
        width: 100%;
        text-align: center;
      }
    }

    ._li:nth-child(3n) {
      margin-right: 0;
    }
  }
}

::v-deep .swiper-scrollbar-drag {
  background: #a88b61;
}

::v-deep .swiper-scrollbar {
  background: #f9f9f9;
}

.swiper {
  width: 100%;
  height: 100%;
  position: relative;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.swiper-slide h4 {
  color: #333;
  font-size: 18px;
  margin-top: 12px;
  margin-bottom: 4px;
}

.swiper-slide p {
  color: #999;
  font-size: 14px;
}

@include respondTo("phone") {
  ._wrap {
    ._bread {
      margin: 12px 0;
    }

    ._first {
      margin: auto;
      display: block;
      ._l {
        width: 100%;
      }
      ._r {
        width: 100%;
        margin-left: 0;
        margin-top: 20px;
      }
    }
    ._two {
      margin: auto;
      margin-top: 20px;
    }
    ._three {
      ._otherImg {
        height: auto;
      }
    }
  }
}
</style>