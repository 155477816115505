<template>
  <div class="_wrap">
    <div class="_auto">
      <myBread
        name="新闻资讯"
        path="/newsInfo"
        :child="detail.title"
        childPath="/newsDetail"
      ></myBread>

      <div class="_content">
        <h4>{{ detail.title }}</h4>
        <p>时间：{{ detail.pub_time }}</p>
        <div class="_html" ref="myHtml" v-html="detail.content"></div>

        <div class="_shar">
          <div>
            <div class="_prev" @click="prev" v-if="detail.prev != '没有了'">
              <img src="../../assets/image/news-img/s.png" alt="" />

              <span>上一条</span>
            </div>
          </div>
          <div class="_wx">
            <span>分享到:</span>
            <!-- <img src="../../assets/image/news-img/wx.png" alt="" /> -->

            <el-popover
              placement="top"
              width="180"
              trigger="hover"
              style="cursor: pointer"
            >
              <div class="_code">
                <div id="qrcode" ref="qrcode"></div>
              </div>
              <img
                slot="reference"
                src="../../assets/image/news-img/wx.png"
                alt=""
              />
            </el-popover>

            <img
              @click="share('wb')"
              src="../../assets/image/news-img/wb.png"
              alt=""
            />
            <img
              @click="share('qq')"
              src="../../assets/image/news-img/qq.png"
              alt=""
            />
          </div>

          <div>
            <div class="_next" @click="next" v-if="detail.next != '没有了'">
              <span>下一条</span>
              <img src="../../assets/image/news-img/x.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <otherBwg />
  </div>
</template>

<script>
import { getDetail } from "@/api/getData";
import QRCode from "qrcodejs2";

export default {
  data() {
    return {
      id: "",
      tit: "",
      detail: {},
    };
  },
  // 动态设置页面标题、关键字和描述等属性
  metaInfo() {
    return {
      title: `${this.detail.title}_龙门石窟研究院_官网`,
      // meta: [
      //   { name: "keywords", content: "" },
      //   { name: "description", content: this.desc },
      // ]
    };
  },
  async mounted() {
    let id = this.$route.query.id;
    this.id = id;
    let tit = this.$route.query.tit;
    this.tit = tit;
    await this.getDetail();
    this.creatQrCode();

    this.$nextTick(() => {
      this.$scallHtmlImg();
    });
  },
  // 监听,当路由发生变化的时候执行
  watch: {
    async $route(to, from) {
      console.log(to);
      let id = to.query.id;
      this.id = id;

      await this.getDetail();
      this.$nextTick(() => {
        this.$scallHtmlImg();
      });
      // setTimeout(() => {

      //   this.$scallHtmlImg();
      // }, 100);
    },
  },
  methods: {
    async getDetail() {
      const params = {
        id: this.id,
      };
      await getDetail(params).then((res) => {
        this.detail = res.data;
      });
    },

    prev() {
      const id = this.detail.prev.id;
      let url = location.origin;
      window.location.href = `${url}/#/newsDetail?id=${id}`;
    },
    next() {
      const id = this.detail.next.id;
      let url = location.origin;
      window.location.href = `${url}/#/newsDetail?id=${id}`;
    },
    creatQrCode() {
      var qrcode = new QRCode(this.$refs.qrcode, {
        text: location.href, // 需要转换为二维码的内容
        width: 150, // 生成二维码得宽度
        height: 150, // 生成二维码得高度
        colorDark: "#000", // 二维码实点的颜色
        colorLight: "#fff", // 二维码背景色
        correctLevel: QRCode.CorrectLevel.H, // （1）QRCode.CorrectLevel.L （2）QRCode.CorrectLevel.M （3）QRCode.CorrectLevel.Q （4）QRCode.CorrectLevel.H
      });
    },
    share(type) {
      if (type == "wb") {
        this.shareWb(
          location.href,
          "龙门石窟研究院",
          this.$IMG_URL + this.detail.cover
        );
      } else {
      }
      this.shareQQ(
        location.href,
        "龙门石窟研究院",
        this.detail.descs,
        this.$IMG_URL + this.detail.cover
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../common/mixin.scss";

._content {
  margin-top: 40px;
  padding: 0 60px;

  > h4 {
    font-size: 26px;
    text-align: center;
  }

  > p {
    color: #999;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 40px;
  }

  ._html {
    min-height: 500px;
  }
}

::v-deep ._html p {
  line-height: 36px;
}

._shar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  margin-top: 20px;
  padding: 20px;
  margin-bottom: 50px;

  ._prev {
    display: flex;
    align-items: center;
    cursor: pointer;

    span {
      margin-left: 10px;
      font-size: 18px;
    }

    img {
      width: 24px;
      height: 24px;
    }
  }

  ._wx {
    display: flex;
    align-items: center;

    span {
      color: #444;
    }

    img {
      width: 15px;
      height: 15px;
      margin-left: 10px;
      cursor: pointer;
    }
  }

  ._next {
    display: flex;
    align-items: center;
    cursor: pointer;

    span {
      margin-right: 10px;
      font-size: 18px;
    }

    img {
      width: 24px;
      height: 24px;
    }
  }
}

@include respondTo("phone") {
  ._wrap {
    margin-top: 100px;
    ._auto {
      width: 100%;
      padding: 0 8px;
      ._bread {
        // margin: 12px 0;
        display: none;
      }
    }

    ._content {
      margin-top: 20px;
      padding: 0;
    }
  }
}
</style>