<template>
  <div class="_wrap">
    <div class="_auto">
      <myBread
        name="文旅融合"
        path="/cultural"
        child="文创产品"
        childPath="/industriesInfo"
        :tChild="detail.title"
        tChildPath="/industriesDetail"
      ></myBread>

      <div class="_content">
        <div class="_l">
          <img :src="$IMG_URL + detail.cover" alt="" />
        </div>
        <div class="_r">
          <h4>{{ detail.title }}</h4>
          <div class="_html" v-html="detail.content"></div>

          <!-- <div class="_btn">购买></div> -->
        </div>
      </div>

      <div class="_div" style="position: relative">
        <div
          style="
            --swiper-navigation-color: #fff;
            --swiper-pagination-color: #fff;
          "
          class="swiper mySwiper2"
        >
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(item, index) in tujiList"
              :key="index"
            >
              <img :src="$IMG_URL + item" />
            </div>
          </div>
        </div>
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
      </div>
      <div thumbsSlider="" class="swiper mySwiper">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="(item, index) in tujiList"
            :key="index"
          >
            <img :src="$IMG_URL + item" />
          </div>
        </div>
      </div>
    </div>
    <otherBwg></otherBwg>
  </div>
</template>

<script>
import { getDetail } from "@/api/getData";
import Swiper from "swiper";
export default {
  data() {
    return {
      id: "",
      detail: {},
      tujiList: [],
    };
  },
  // 动态设置页面标题、关键字和描述等属性
  metaInfo() {
    return {
      title: `${this.detail.title}_龙门石窟研究院_官网`,
      // meta: [
      //   { name: "keywords", content: "" },
      //   { name: "description", content: this.desc },
      // ]
    };
  },
  async mounted() {
    this.id = this.$route.query.id;
    await this.getDetail();
    this.initSwiper();
  },
  methods: {
    async getDetail() {
      const params = {
        id: this.id,
      };
      await getDetail(params).then((res) => {
        this.detail = res.data;
        this.tujiList = res.data.tuji.split(",");
        // this.tujiList = this.tujiList.slice(0,4)
      });
    },
    initSwiper() {
      var swiper = new Swiper(".mySwiper", {
        autoplay: {
          delay: 3000,

        },
        spaceBetween: 10,
        slidesPerView: 5,
        freeMode: true,
        watchSlidesProgress: true,
      });
      var swiper2 = new Swiper(".mySwiper2", {
        autoplay: {
          delay: 3000,
          
        },
        spaceBetween: 10,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        thumbs: {
          swiper: swiper,
        },
      });

      swiper2.el.onmouseover = function () {
        swiper.autoplay.stop();
        swiper2.autoplay.stop();
      };
      swiper2.el.onmouseout = function () {
        swiper.autoplay.start();
        swiper2.autoplay.start();

      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../common/mixin.scss";

::v-deep ._html p {
  line-height: 30px;
  color: #333;
  font-size: 18px;
}
._content {
  margin: 0 20px;
  // display: flex;
  padding-bottom: 20px;

  ._l {
    width: 486px;
    height: 310px;
    overflow: hidden;
    border: 1px solid #ccc;
    float: left;
    margin-right: 20px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      transition: all 0.5s;
    }

    img:hover {
      transform: scale(1.1);
    }
  }

  ._r {
    // flex: 1;
    // margin-left: 20px;

    h4 {
      font-size: 24px;
      color: #333;
      font-weight: bold;
      margin-bottom: 27px;
      margin-top: 7px;
    }

    p {
      font-size: 18px;
      text-indent: 2em;
      line-height: 32px;
      height: 130px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 4;
      /* 设置最大显示行数 */
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
    }

    ._btn {
      width: 122px;
      height: 42px;
      border-radius: 6px;
      border: 1px solid #a88b61;
      color: #a88b61;
      text-align: center;
      line-height: 42px;
      margin-top: 70px;
      cursor: pointer;
    }
  }
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.swiper {
  width: 72%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
}

.mySwiper2 {
  height: 518px;
  width: 72%;
  overflow: hidden;
  position: relative;
}

.mySwiper {
  height: 200px;
  box-sizing: border-box;
  padding: 10px 0;
}

.mySwiper .swiper-slide {
  // height: 100%;
  opacity: 0.4;
  height: 100px;
}

.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
  border: 3px solid #ba9258;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.swiper-button-prev::after {
  content: "";
}

.swiper-button-prev {
  top: 111%;
  left: 120px;
  width: 32px;
  height: 32px;
  background-image: url("../../assets/image/research-img/l.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.swiper-button-next {
  top: 111%;
  right: 120px;
  width: 32px;
  height: 32px;
  background-image: url("../../assets/image/research-img/r.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.swiper-button-next::after {
  content: "";
}



@include respondTo('phone'){
  ._wrap{
    ._bread{
      display: none;
    }

    ._content{
      margin: auto;
      ._l{
        width: 100%;
        border: none;
      }
    }


    .mySwiper2{
      width: 100%;
    }
    .swiper{
      width: 100%;
    }

    .swiper-button-next{
      display: none;
    }
    .swiper-button-prev{
      display: none;
    }
  }
}
</style>