<template>
  <div class="_wrap">
    <div class="_auto">
      <!-- <myBread name="石窟专委会" path="/committee" :child="tit" :childPath="'/meetInfo?tit='+tit"></myBread> -->
      <myBread
        name="石窟专委会"
        path="/committee"
        :child="tit"
        :childPath="'/meetInfo?tit='+tit"
        :tChild='detail.title'
        tChildPath='/meetDetail'

      ></myBread>

      <div class="_content">
        <h4>{{ detail.title }}</h4>
        <p>时间：{{ detail.pub_time }}</p>

        <div class="_html" ref="myHtml" v-html="detail.content"></div>
      </div>

      <div class="_shar">
        <div>
          <div class="_prev" v-if="detail.prev != '没有了'" @click="prev">
            <img src="../../assets/image/news-img/s.png" alt="" />

            <span>上一条</span>
          </div>
        </div>

        <div class="_wx">
          <span>分享到:</span>
          <!-- <img src="../../assets/image/news-img/wx.png" alt="" /> -->

          <el-popover
            placement="top"
            width="180"
            trigger="hover"
            style="cursor: pointer"
          >
            <div class="_code">
              <div id="qrcode" ref="qrcode"></div>
            </div>
            <img
              slot="reference"
              src="../../assets/image/news-img/wx.png"
              alt=""
            />
          </el-popover>

          <img
            @click="share('wb')"
            src="../../assets/image/news-img/wb.png"
            alt=""
          />
          <img
            @click="share('qq')"
            src="../../assets/image/news-img/qq.png"
            alt=""
          />
        </div>

        <div>
          <div class="_next" v-if="detail.next != '没有了'" @click="next">
            <span>下一条</span>
            <img src="../../assets/image/news-img/x.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <otherBwg></otherBwg>
  </div>
</template>

<script>
import { getDetail } from "@/api/getData";
import QRCode from "qrcodejs2";
export default {
  data() {
    return {
      id: "",
      tit: "",
      detail: {},
    };
  },
  // 动态设置页面标题、关键字和描述等属性
  metaInfo() {
    return {
      title: `${this.detail.title}_龙门石窟研究院_官网`,
      // meta: [
      //   { name: "keywords", content: "" },
      //   { name: "description", content: this.desc },
      // ]
    };
  },

  async mounted() {
    this.tit = this.$route.query.tit;
    this.id = this.$route.query.id;
    await this.getDetail();
    this.creatQrCode();
    setTimeout(() => {
      this.$scallHtmlImg();
    }, 50);

  },

  // 监听,当路由发生变化的时候执行
  watch: {
    $route(to, from) {
      console.log(to);
      let id = to.query.id;
      this.id = id;
      this.getDetail();
    },
  },
  methods: {
    async getDetail() {
      const params = {
        id: this.id,
      };
      await getDetail(params).then((res) => {
        this.detail = res.data;
      });
    },

    prev() {
      let url = location.origin;
      window.location.href = `${url}/#/meetDetail?id=${this.detail.prev.id}&tit=${this.tit}`;
    },

    next() {
      let url = location.origin;
      window.location.href = `${url}/#/meetDetail?id=${this.detail.next.id}&tit=${this.tit}`;
    },
    creatQrCode() {
      var qrcode = new QRCode(this.$refs.qrcode, {
        text: location.href, // 需要转换为二维码的内容
        width: 150, // 生成二维码得宽度
        height: 150, // 生成二维码得高度
        colorDark: "#000", // 二维码实点的颜色
        colorLight: "#fff", // 二维码背景色
        correctLevel: QRCode.CorrectLevel.H, // （1）QRCode.CorrectLevel.L （2）QRCode.CorrectLevel.M （3）QRCode.CorrectLevel.Q （4）QRCode.CorrectLevel.H
      });
    },
    share(type) {
      if (type == "wb") {
        this.shareWb(
          location.href,
          "龙门石窟研究院",
          this.$IMG_URL + this.detail.cover
        );
      } else {
      }
      this.shareQQ(
        location.href,
        "龙门石窟研究院",
        this.detail.descs,
        this.$IMG_URL + this.detail.cover
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../common/mixin.scss";

._content {
  padding-bottom: 60px;

  h4 {
    font-size: 26px;
    margin-bottom: 20px;
    text-align: center;
  }

  > p {
    color: #999999;
    text-align: center;
  }

  ._html {
    padding: 0 60px;
    margin-top: 40px;
  }
}

._shar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  margin-top: 20px;
  padding: 20px;
  margin-bottom: 50px;

  ._prev {
    display: flex;
    align-items: center;
    cursor: pointer;

    span {
      margin-left: 10px;
      font-size: 18px;
    }

    img {
      width: 24px;
      height: 24px;
    }
  }

  ._wx {
    display: flex;
    align-items: center;

    span {
      color: #444;
    }

    img {
      width: 15px;
      height: 15px;
      margin-left: 10px;
      cursor: pointer;
    }
  }

  ._next {
    display: flex;
    align-items: center;
    cursor: pointer;

    span {
      margin-right: 10px;
      font-size: 18px;
    }

    img {
      width: 24px;
      height: 24px;
    }
  }
}

@include respondTo('phone'){
  ._wrap{
    ._bread{
      display: none;
    }
    ._content{
      ._html{
        padding: 0;
        margin-top: 20px;
      }
    }
  }
}
</style>