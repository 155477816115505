<template>
  <div class="_nav">
    <div class="_top">
      <div class="_top_a">
        <p>欢迎光临龙门石窟研究院官网!</p>
        <div class="_r">
          <span @click="set" style="cursor: pointer">设为首页</span>
          <span class="_shu">|</span>
          <span @click="shouc" style="cursor: pointer">收藏本站</span>
        </div>
      </div>
    </div>
    <div class="_nav_auto nav_logo">
      <div class="_mid">
        <div class="_logoBox" @click="$router.push({ path: '/' })">
          <img
            src="../../assets/image/nav-img/logo.png"
            alt="龙门石窟研究院官网"
          />
        </div>

        <div class="_search">
          <div class="_iptBox">
            <input
              type="text"
              class="_ipt"
              v-model="keywords"
              placeholder="请输入搜索内容"
            />
          </div>

          <div class="_btn" @click="goSearch">
            <img src="../../assets/image/nav-img/search.png" alt="" />
          </div>
        </div>
      </div>
    </div>

    <div class="_navList">
      <!-- pc端导航 -->
      <div class="_nav_auto">
        <el-menu
          router
          :default-active="defaultActive"
          class="el-menu-demo"
          menu-trigger="click"
          text-color="rgb(204, 204, 204)"
          active-text-color="#fff"
          mode="horizontal"
        >
          <el-menu-item
            v-for="(item, index) in listMenu"
            :key="index"
            :index="item.id"
            class="header-title"
          >
            <span class="_sp">{{ item.title }}</span>
          </el-menu-item>
        </el-menu>
      </div>

      <!-- 手机端导航 -->

      <div class="_phone">
        <img
          src="../../assets/image/nav-img/logo.png"
          alt="龙门石窟研究院官网"
          @click="$router.push({ path: '/' })"
        />

        <img
          src="../../assets/image/home-img/more.png"
          alt=""
          @click="lookPhone"
        />
      </div>
    </div>

    <phoneMenu ref="phoneMenuRef"></phoneMenu>
  </div>
</template>

<script>
import { listMenu } from "./list";
import phoneMenu from "./phoneMenu.vue";
export default {
  components: {
    phoneMenu,
  },
  data() {
    return {
      keywords: "",
      listMenu: listMenu,
    };
  },
  computed: {
    defaultActive() {
      return this.$route.path;
    },
  },
  created() {},
  methods: {
    goSearch() {
      let url = location.origin;
      window.open(`${url}/#/searchInfo?key=${this.keywords}`);
    },

    set() {
      this.SetHome(this, location.origin);
    },
    shouc() {
      this.addFavorite();
    },

    //设为首页
    SetHome(obj, url) {
      try {
        obj.style.behavior = "url(#default#homepage)";
        obj.setHomePage(url);
      } catch (e) {
        if (window.netscape) {
          try {
            netscape.security.PrivilegeManager.enablePrivilege(
              "UniversalXPConnect"
            );
          } catch (e) {
            alert(
              "抱歉，此操作被浏览器拒绝！\n\n请在浏览器地址栏输入“about:config”并回车然后将[signed.applets.codebase_principal_support]设置为'true'"
            );
          }
        } else {
          alert(
            "抱歉，您所使用的浏览器无法完成此操作。\n\n您需要手动将【" +
              url +
              "】设置为首页。"
          );
        }
      }
    },

    addFavorite() {
      var url = window.location;
      var title = document.title;
      var ua = navigator.userAgent.toLowerCase();
      if (ua.indexOf("360se") > -1) {
        alert("由于360浏览器功能限制，请按 Ctrl+D 手动收藏！");
      } else if (ua.indexOf("msie 8") > -1) {
        window.external.AddToFavoritesBar(url, title); //IE8
      } else if (document.all) {
        //IE类浏览器
        try {
          window.external.addFavorite(url, title);
        } catch (e) {
          alert("您的浏览器不支持,请按 Ctrl+D 手动收藏!");
        }
      } else if (window.sidebar) {
        //firfox等浏览器；
        window.sidebar.addPanel(title, url, "");
      } else {
        alert("您的浏览器不支持,请按 Ctrl+D 手动收藏!");
      }
    },

    lookPhone() {
      this.$refs.phoneMenuRef.open();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../common/mixin.scss";

._nav {
  width: 100%;
  background-image: url("../../assets/image/nav-img/top-bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;

  ._nav_auto {
    max-width: 1280px;
    margin: 0 auto;
  }
}

._top {
  height: 45px;
  border-bottom: 1px solid #eeeeee;

  ._top_a {
    max-width: 1280px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  p {
    font-size: 16px;
    opacity: 0.5;
  }

  ._r {
    color: #999;
    font-size: 14px;

    ._shu {
      margin: 0 10px;
    }
  }
}

._mid {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 8px;

  ._logoBox {
    cursor: pointer;
    img {
    }
  }

  ._search {
    display: flex;
    align-items: center;

    ._iptBox {
      ._ipt {
        width: 398px;
        height: 45px;
        background: #ffffff;
        border: 1px solid #e8e8e8;
        font-size: 16px;
        padding-left: 16px;
      }
    }

    ._btn {
      width: 62px;
      height: 45px;
      background: #a88b61;
      text-align: center;
      cursor: pointer;

      img {
        margin-top: 10px;
      }
    }
  }
}

._navList {
  width: 100%;
  height: 60px;
  background: #a88b61;

  .el-menu-demo {
    width: 100%;
    height: 100%;
  }

  .el-menu--horizontal > .el-menu-item.is-active {
    border: none;
    font-weight: bold;
  }

  .el-menu--horizontal > .el-menu-item.is-active span {
    color: white;
  }

  .el-menu--horizontal .el-menu-item {
    padding: 0 40px;
    font-size: 22px;
    border: none;
    line-height: 56px;
  }
}

._sp {
  color: #f3e6d2;
}

.el-menu {
  // background: unset;
  background: none; /* 重置背景图片 */
  background-color: transparent; /* 重置背景颜色为透明 */
  background-position: initial; /* 重置背景位置为初始值 */
  background-repeat: initial; /* 重置背景平铺为初始值 */
  background-size: initial; /* 重置背景尺寸为初始值 */
  background-attachment: initial; /* 重置背景固定状态为初始值 */
  background-clip: initial; /* 重置背景裁剪为初始值 */
  background-origin: initial; /* 重置背景原点为初始值 */
  background-image: initial; /* 重置背景图片为初始值 */
}

.el-menu.el-menu--horizontal {
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
.el-menu--horizontal > .el-submenu .el-submenu__title:hover {
  background-color: transparent !important;
}

::v-deep .el-menu--horizontal .el-menu .el-menu-item,
.el-menu--horizontal .el-menu .el-submenu__title {
  background-color: #fff !important;
}
._phone{
  display: none;
}
@include respondTo("phone") {
  ._top_a {
    padding: 0 10px;
  }

  ._mid ._search {
    display: none;
  }

  ._top{
    display: none;
  }

  ._nav_auto {
    display: none;
  }

  ._navList {
    height: 100px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    ._phone {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 10px;
    }
  }
}
</style>