<template>
  <el-drawer
    title=""
    :visible.sync="drawer"
    :direction="direction"
    size="100%"
    :with-header="false"
    :append-to-body="true"
    :modal-append-to-body="false"
  >
    <div class="_drawer">
      <div class="_close" @click="drawer = false">
        <img
          src="@/assets/image/home-img/close.png"
          alt="八路军驻洛办事处纪念馆-官方网站"
        />
      </div>

      <div class="_content">
        <div class="_logo" @click="goHome">
          <img
            src="@/assets/image/nav-img/logo.png"
            alt="八路军驻洛办事处纪念馆-官方网站"
          />
        </div>

        <div class="phoneMenu">
          <div class="_li" v-for="(item, index) in listMenu" :key="index">
            <h4 @click="goRoute(item)">{{ item.title }}</h4>
            <!-- <div class="_child" v-if="item.child.length > 0">
              <p v-for="(v, i) in item.child" :key="i" @click="go(item, v)">
                {{ v.subtit }}
              </p>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import { listMenu } from "./list";
export default {
  data() {
    return {
      drawer: false,
      direction: "ttb",
      listMenu: listMenu,
    };
  },

  methods: {
    open() {
      this.drawer = true;
    },

    goHome() {
      this.drawer = false;
      this.$router.push({
        path: `/`,
      });
    },

    goRoute(item) {
      console.log(item);
      this.drawer = false;
      setTimeout(() => {
        this.$router.push({
          path: `${item.id}`,
        });
      }, 50);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../common/mixin.scss";
._drawer {
  overflow: auto;
}
._close {
  width: 18px;
  height: 18px;
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
  }
}

._content {
  margin-top: 50px;
  ._logo {
    text-align: center;
  }
}
.phoneMenu {
  width: 100%;
  margin-top: 20px;
  ._li {
    margin-bottom: 15px;
    h4 {
      color: #eed798;
      font-size: 20px;
      margin-left: 20px;
    }
    ._child {
      margin-top: 10px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      background-color: #8c3123;
      padding: 10px 20px;

      p {
        color: #e1cf9e;
        font-size: 16px;
        cursor: pointer;
        margin-right: 4.5%;
        line-height: 30px;
      }
    }
  }
}
::v-deep .el-drawer__body {
  background-color: #a88b61;
}
</style>