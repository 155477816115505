<template>
    <div class="_wrap">
        <div class="_auto">

                <myBread name="法律声明" path='/notice'></myBread>


            <div class="_h4">
                <p>法律声明</p>
            </div>
            <div class="_content" >
                <div class="_html" v-html="detail.content"></div>
               
            </div>


        </div>

        <other-bwg></other-bwg>
    </div>
</template>


<script>
import { getArticle } from "@/api/getData";
export default {
  data() {
    return {
        detail:{},
    };
  },
        // 动态设置页面标题、关键字和描述等属性  
        metaInfo() {

return {
  title: `法律声明_龙门石窟研究院_官网`,
  // meta: [
  //   { name: "keywords", content: "" },
  //   { name: "description", content: this.desc },
  // ]
}
},
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      const params = {
        cate_id: "8",
      };
      getArticle(params).then((res) => {
        this.detail = res.data.data[0]
      });
    },
  },
};
</script>

<style lang="scss" scoped>

@import "../../common/mixin.scss";

._content{
    padding: 20px 60px;
    margin-top: 10px;
    min-height: 500px;
    line-height: 30px;
}
._h4
{
    height: 60px;
    background-color: #f9f9f9;
    border-top: 1px solid #A88B61;
    margin: 0 20px;

    p
    {
        width: 148px;
        height: 100%;
        font-size: 22px;
        color: white;
        background: #A88B61;
        text-align: center;
        line-height: 60px;
    }
}

::v-deep ._html p{
    line-height: 36px;
   
}
::v-deep ._html p span{
    background: transparent !important;
}


@include respondTo('phone'){
  ._wrap{
    ._bread{
      margin: 12px 0;
    }
    ._h4{
      margin: auto;
    }
    ._content{
      padding: 0;
    }
  }
}
</style>