<template>
  <div class="_wrap">
    <div class="_auto">
      <div class="_bread">
        <myBread name="机构概况" path="/insPro"></myBread>
      </div>

      <div class="_l_r" id="znjj">
        <div class="_l">
          <div class="_h4">
            <p>职能简介</p>
          </div>
          <div
            class="_cont"
            v-if="znjj.length > 0"
            v-html="znjj[0].content"
          ></div>
        </div>

        <div class="_r _l">
          <div class="_h4">
            <p>领导班子</p>
          </div>

          <div class="_bList">
            <div class="_li" v-for="(item, index) in ldbz" :key="index">
              <div class="_img">
                <img :src="$IMG_URL + item.cover" alt="" />
              </div>
              <div class="_name">
                <h4>{{ item.title }}</h4>
                <p>{{ item.sub_title }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="_jgsz" id="jgsz">
        <div class="_h4">
          <p>机构设置</p>
        </div>

        <div class="_sz">
          <h4>龙门石窟研究院</h4>

          <div class="_xian">
            <img src="../../assets/image/ins-img/ss.png" alt="" />
          </div>

          <div class="_insAll">
            <div class="_first">
              <div class="_li" v-for="(item, index) in orgList[1]" :key="index">
                <img src="../../assets/image/commit-img/dot.png" alt="" />
                <p>{{ item.c_name }}</p>
              </div>
            </div>

            <div class="_first _two">
              <div
                :class="{ _li: true, _special: item.c_id == 64 }"
                v-for="(item, index) in orgList[2]"
                :key="index"
                @click="lookDetail(item)"
              >
                <img src="../../assets/image/commit-img/dot.png" alt="" />
                <p v-if="item.c_id != 64">{{ item.c_name }}</p>

                <p v-else>
                  <span class="_sp1"> ({{ special[1] }}</span>
                  <span class="_sp2">{{ special[0] }}</span>
                </p>
              </div>
            </div>

            <div class="_first _three">
              <div
                class="_li"
                v-for="(item, index) in orgList[0]"
                :key="index"
                v-if="item.c_id != 72"
              >
                <img src="../../assets/image/commit-img/dot.png" alt="" />
                <p>{{ item.c_name }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="_jgyg" id="jgyg">
        <div class="_h4">
          <p>机构沿革</p>
        </div>

        <div class="_yg">
          <!-- <img src="../assets/image/ins-img/yg.png" alt=""> -->

          <div class="_list">
            <div class="_li" v-for="(item, index) in jgyg" :key="index">
              <h4>
                <span class="_time">{{ item.title }}，</span>
                <span>{{ item.sub_title }}</span>
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <otherBwg></otherBwg>

    <insModel ref="insModelRef"></insModel>
  </div>
</template>

<script>
import { getArticle, getOrganization } from "@/api/getData";
import insModel from "./insModel.vue";
export default {
  components: {
    insModel,
  },
  data() {
    return {
      znjj: [],
      ldbz: [],
      jgsz: [],
      jgyg: [],
      orgList: [],

      special: [],
    };
  },
  // 动态设置页面标题、关键字和描述等属性
  metaInfo() {
    return {
      title: "机构概况_龙门石窟研究院_官网",
      // meta: [
      //   { name: "keywords", content: "" },
      //   { name: "description", content: this.desc },
      // ]
    };
  },
  async mounted() {
    await this.getData();

    let tit = this.$route.query.tit;
    console.log(tit);
    const obj = {
      职能简介: "znjj",
      领导班子: "znjj",
      机构设置: "jgsz",
      机构沿革: "jgyg",
    };
    for (let i in obj) {
      if (tit == i) {
        console.log(obj[i]);
        this.$scrollToSection(obj[i]);
        return;
      } else {
        window.scrollTo({
          top: 0,
          behavior: "smooth", // 平滑滚动
        });
      }
    }
  },
  methods: {
    async getData() {
      const params = {
        cate_id: "11", //职能简介
      };
      await getArticle(params).then((res) => {
        this.znjj = res.data.data;
      });

      const params1 = {
        cate_id: "10", //领导班子
        pagesize: 5,
      };
      await getArticle(params1).then((res) => {
        this.ldbz = res.data.data;
      });

      const params2 = {
        cate_id: "12", //机构设置
      };
      await getArticle(params2).then((res) => {
        this.jgsz = res.data.data;
      });

      const params3 = {
        cate_id: "13", //机构沿革
      };
      await getArticle(params3).then((res) => {
        this.jgyg = res.data.data;
      });

      // 机构设置
      await getOrganization().then((res) => {
        this.orgList = res.data;
        let data = this.orgList[2];
        let name;
        for (let i in data) {
          if (data[i].c_id == 64) {
            name = data[i].c_name;
          }
        }
        console.log(name);

        let b = name.split("(");
        console.log(b);
        this.special = b;
      });
    },
    lookDetail(item) {
      this.$refs.insModelRef.edit(item);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../common/mixin.scss";
@mixin none() {
  border-bottom: none;
  border-left: none;
  border-right: none;
}

._h4 {
  height: 60px;
  background-color: #f9f9f9;
  border-top: 1px solid #a88b61;
  margin: 0 20px;

  p {
    width: 148px;
    height: 100%;
    font-size: 22px;
    color: white;
    background: #a88b61;
    text-align: center;
    line-height: 60px;
  }
}

._l_r {
  display: flex;

  ._l {
    width: 57.8%;
    margin-left: 20px;

    > ._h4 {
      margin: 0;
      width: 100%;
    }

    ._cont {
      background: #f9f9f9;
      margin-top: 20px;
      padding: 20px;
      height: 476px;
      overflow-y: scroll;
    }

    ._cont::-webkit-scrollbar {
      width: 8px;
      height: 10px;
      background-color: transparent;
    }

    ._cont::-webkit-scrollbar-thumb {
      border-radius: 20px;
      background-color: rgba(144, 147, 153, 0.3);
    }
  }

  ._r {
    flex: 1;
    margin-left: 30px;

    ._bList {
      ._li {
        display: flex;
        align-items: center;
        border-bottom: 1px dotted #e9e9e9;
        padding: 14px 10px;
      }

      ._img {
        width: 60px;
        height: 70px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      ._name {
        margin-left: 45px;

        h4 {
          font-size: 24px;
          color: #333;
          font-weight: bold;
          margin-bottom: 6px;
        }

        p {
          font-size: 20px;
          color: #333;
        }
      }
    }
  }
}

::v-deep ._cont p {
  line-height: 38px;
  font-size: 18px;
}

._jgsz {
  margin-top: 50px;

  ._sz {
    text-align: center;
    margin-top: 20px;
    position: relative;
    height: 710px;

    > h4 {
      font-size: 20px;
      text-align: center;
      width: 200px;
      height: 58px;
      line-height: 58px;
      background: #f8f5ea;
      border-radius: 10px;
      margin: 0 auto;
      font-weight: bold;
      color: #a88b61;
    }

    ._insAll {
      margin-top: 20px;
      ._first {
        position: absolute;
        left: 50px;
        ._li {
          display: inline-block;
          padding: 20px 15px 30px 15px;
          background: #f9f9f9;
          border-radius: 3px;
          cursor: pointer;
          margin-right: 50px;
          height: 160px;
          p {
            font-size: 18px;
            color: #333;
            margin-top: 10px;
            writing-mode: vertical-lr;
            letter-spacing: 4px;
          }
        }
        ._li:hover {
          background-color: #f8f5ea;
        }
        ._li:hover p {
          color: #a88b61;
          font-weight: bold;
        }
      }
      ._two {
        position: absolute;
        left: 360px;
        ._li {
          margin-right: 26px;
          height: 400px;
        }

        ._special {
          width: 78px;
        }
      }
      ._three {
        position: absolute;
        left: auto;
        right: 50px;
        ._li {
          margin-left: 25px;
          margin-right: 0;
          height: 480px;
        }
      }
    }
  }
}

._jgyg {
  margin-top: 60px;

  ._yg {
    margin-top: 40px;
    margin-bottom: 50px;

    ._list {
      border-left: 1px solid;
      border-image: linear-gradient(to bottom, #a88b61, #a88b61) 1;
      width: 80%;
      margin: 0 auto;
    }

    ._li {
      padding: 15px 0;
      padding-left: 100px;
      position: relative;

      h4 {
        ._time {
          font-weight: bold;
          color: #333;
        }

        span {
          color: #666;
          font-size: 20px;
        }
      }
    }

    ._li::before {
      content: "";
      display: inline-block;
      width: 9px;
      height: 9px;
      border: 1px solid #ab8f67;
      border-radius: 50%;
      position: absolute;
      left: -6px;
      top: 22px;
    }

    ._li::after {
      content: "";
      display: inline-block;
      width: 3px;
      height: 3px;
      background: #ab8f67;
      border-radius: 50%;
      position: absolute;
      left: -2px;
      top: 26px;
    }

    ._li:last-child::before {
      width: 19px;
      height: 19px;
      left: -11px;
      top: 18px;
    }

    ._li:last-child::after {
      width: 7px;
      height: 7px;
      left: -4px;
      top: 25px;
    }
  }
}

@include respondTo("phone") {
  ._wrap {
    margin-top: 100px;
    ._auto {
      width: 100%;
      padding: 0 8px;
      ._bread {
        margin: 12px 0;
      }
    }
  }


  ._l_r{
    display: block;
    ._l{
      width: 100%;
      margin-left: 0;
    }

    ._r{
      margin-top: 20px;
    }
  }

  ._jgsz{
    margin-top: 20px;
    ._h4{
      margin: auto;
    }
  }

  ._jgyg{
    margin-top: 20px;
    ._h4{
      margin: auto;
    }
    ._yg{
      margin-top: 20px;
      ._li{
        padding-left: 60px;
      }
    }
  }
}
</style>




