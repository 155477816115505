<template>
  <div class="_wrap">
    <div class="_auto">
      <myBread
        name="保护研究"
        path="/research"
        :child="detail.title"
        childPath="/art"
      ></myBread>

      <div class="_content">
        <h4 class="_title">{{ detail.title }}</h4>

        <div class="_div" style="position: relative">
          <div
            style="
              --swiper-navigation-color: #fff;
              --swiper-pagination-color: #fff;
            "
            class="swiper mySwiper2"
          >
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="(item, index) in tujiList"
                :key="index"
              >
                <img :src="$IMG_URL + item" />
              </div>
            </div>
          </div>
          <div class="swiper-button-next"></div>
          <div class="swiper-button-prev"></div>
        </div>
        <div thumbsSlider="" class="swiper mySwiper">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide"
              v-for="(item, index) in tujiList"
              :key="index"
            >
              <img :src="$IMG_URL + item" />
            </div>
          </div>
        </div>

        <div class="_html" v-html="detail.content"></div>
      </div>
    </div>

    <otherBwg></otherBwg>
  </div>
</template>

<script>
import { getArticle } from "@/api/getData";
import Swiper from "swiper";
export default {
  data() {
    return {
      queryParams: {
        cate_id: "57", //装饰艺术
      },
      detail: {},
      tujiList: [],
    };
  },
  // 动态设置页面标题、关键字和描述等属性
  metaInfo() {
    return {
      title: `${this.detail.title}_龙门石窟研究院_官网`,
      // meta: [
      //   { name: "keywords", content: "" },
      //   { name: "description", content: this.desc },
      // ]
    };
  },
  async mounted() {
    let art = this.$route.query.art;
    if (art == "装饰艺术") {
      this.queryParams.cate_id = "57";
    } else if (art == "雕刻艺术") {
      this.queryParams.cate_id = "58";
    } else if (art == "书法艺术") {
      this.queryParams.cate_id = "59";
    }
    await this.getData();
    this.initSwiper();
  },
  methods: {
    initSwiper() {
      var swiper = new Swiper(".mySwiper", {
        spaceBetween: 10,
        slidesPerView: 5,
        freeMode: true,
        watchSlidesProgress: true,
      });
      var swiper2 = new Swiper(".mySwiper2", {
        spaceBetween: 10,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        thumbs: {
          swiper: swiper,
        },
      });
    },

    async getData() {
      await getArticle(this.queryParams).then((res) => {
        this.detail = res.data.data[0];
        console.log(this.detail.tuji);
        let arr = this.detail.tuji.split(",");
        this.tujiList = arr;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../common/mixin.scss";
._content {
  padding-bottom: 80px;

  ._title {
    font-size: 26px;
    color: #333333;
    text-align: center;
    margin: 40px 0;
  }

  ._html {
    margin-top: 40px;
    padding: 0 60px;
  }
}

::v-deep ._html p {
  line-height: 36px;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.swiper {
  width: 72%;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
}

.mySwiper2 {
  height: 518px;
  width: 72%;
  overflow: hidden;
  position: relative;
}

.mySwiper {
  height: 100px;
  box-sizing: border-box;
  margin: 10px auto;
}

.mySwiper .swiper-slide {
  height: 100px;
  opacity: 0.4;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.swiper-button-prev::after {
  content: "";
}

.swiper-button-prev {
  top: 111%;
  left: 120px;
  width: 32px;
  height: 32px;
  background-image: url("../../assets/image/research-img/l.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.swiper-button-next {
  top: 111%;
  right: 120px;
  width: 32px;
  height: 32px;
  background-image: url("../../assets/image/research-img/r.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.swiper-button-next::after {
  content: "";
}

@include respondTo("phone") {
  ._wrap {
    ._bread {
      display: none;
    }
    ._auto {
      ._content {
        ._title {
          margin: 20px 0;
        }
      }
    }

    .swiper{
        width: 80%;
        height: 60px;
    }
    .mySwiper .swiper-slide{
        height: 100%;
    }
    .mySwiper2{
        width: 100%;
        height: 300px;
    }

    .swiper-button-next{
        right: 0;
        top: 115%;
    }
    .swiper-button-prev{
        left: 0;
        top: 115%;
    }

    ._html{
        margin-top: 20px;
        padding: 0;
    }
  }
}
</style>